



























































import Vue from "vue";
import { WebinarAvailability } from "@/clients/clients";
import { mapGetters } from "vuex";
import defaultImage from "../../assets/fall-g3a2b20769_1920.jpg";

export default Vue.extend({
  name: "WebinarPromotionalImage",
  props: {
    registered: {
      type: Boolean,
      default: false,
    },
    joinUrl: {
      type: String,
      default: "",
    },
    promotionalImageUri: {
      type: String,
      default: "",
    },
    availability: {
      type: String,
      default: "",
    },
    featured: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["appLoading", "assetBaseUrl"]),
    webinarAvailability(): typeof WebinarAvailability {
      return WebinarAvailability;
    },
    webinarImage(): string {
      return this.assetBaseUrl.length && this.promotionalImageUri
        ? `${this.assetBaseUrl}/${this.promotionalImageUri}`
        : defaultImage;
    },
  },
});
