

















































































































import Vue, { PropType } from "vue";
import {
  SpeakerPublicResponse,
  UserCertificateResponse,
  WebinarAvailability,
  WebinarsItemPublicResponse,
} from "@/clients/clients";
import { format } from "date-fns";
import WebinarAddToCalendar from "@/components/webinars/WebnarAddToCalendar.vue";
import AppButtonDownloadCertificate from "@/components/certificates/AppButtonDownloadCertificate.vue";

export default Vue.extend({
  name: "WebinarSummary",
  components: { AppButtonDownloadCertificate, WebinarAddToCalendar },
  props: {
    webinar: {
      type: Object as PropType<WebinarsItemPublicResponse>,
      default: () => new WebinarsItemPublicResponse(),
    },
    enrolled: { type: Boolean, default: false },
    joinUrl: {
      type: String,
      default: "",
    },
    certificate: {
      type: Object as PropType<UserCertificateResponse | null>,
      default: () => null,
    },
  },
  data: () => ({}),
  computed: {
    webinarAvailability(): typeof WebinarAvailability {
      return WebinarAvailability;
    },
  },
  methods: {
    formattedDate(): string {
      if (this.webinar?.date) {
        return format(this.webinar.date, "eeee, do MMMM yyyy");
      }
      return "";
    },
    formattedSpeakerShortName(speaker: SpeakerPublicResponse): string {
      return [speaker.title, speaker.fullName]
        .filter((p) => p?.length)
        .join(" ");
    },
  },
});
