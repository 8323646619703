



























import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "AppGdprDialog",
  props: {
    display: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    ...mapActions("UserStore", ["updateGdprConsent"]),
    async agree() {
      await this.updateGdprConsent(true);
      this.$emit("update:agree");
    },
    disagree() {
      this.$emit("update:disagree");
    },
  },
});
