






import Vue, { PropType } from "vue";
import { UserCertificateResponse, UserClient } from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "AppButtonDownloadCertificate",
  props: {
    certificate: Object as PropType<UserCertificateResponse>,
  },
  methods: {
    async download(): Promise<void> {
      if (!this.certificate?.id) return;
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const client = this.$httpClientFactory(UserClient, token.accessToken);
      const response = await client.getCertificate(this.certificate.id);
      if (!response.data) return;
      let link = document.createElement("a");
      link.download = response.fileName ?? "certificate.pdf";
      link.href = URL.createObjectURL(response.data);
      link.click();
      URL.revokeObjectURL(link.href);
      link.remove();
    },
  },
});
