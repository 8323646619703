
































































































import Vue, { PropType } from "vue";
import {
  SpeakerPublicResponse,
  WebinarsItemPublicResponse,
} from "@/clients/clients";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "WebinarDetailsTabs",
  props: {
    webinar: Object as PropType<WebinarsItemPublicResponse>,
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters(["appLoading", "assetBaseUrl"]),
    minContentHeight(): string {
      if (this.$refs["descriptionPanel"]) {
        const height = (this.$refs["descriptionPanel"] as HTMLElement)
          ?.offsetHeight;
        return `${height}px`;
      }
      return "300px";
    },
  },
  methods: {
    formattedSpeakerFullName(speaker: SpeakerPublicResponse): string {
      return [speaker.title, speaker.fullName, speaker.postNominalLetters]
        .filter((p) => p?.length)
        .join(" ");
    },
  },
});
