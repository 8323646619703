





import Vue from "vue";
import { mapGetters } from "vuex";
import { WebinarRegistration } from "@/clients/clients";
import WebinarEnrolledView from "@/views/webinar/WebinarEnrolledView.vue";
import WebinarPublicView from "@/views/webinar/WebinarPublicView.vue";

export default Vue.extend({
  name: "WebinarView",
  metaInfo: {
    title: "Webinars",
  },
  components: { WebinarPublicView, WebinarEnrolledView },
  props: {
    id: String,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("UserStore", ["user", "userGdprConsent"]),
    enrolled(): boolean {
      return this.user.webinars?.some(
        (p: WebinarRegistration) => p.id === this.id
      );
    },
  },
});
