





































































import Vue from "vue";
import {
  ApiException,
  GenerateCertificateRequest,
  SpeakerPublicResponse,
  UserCertificateResponse,
  UserClient,
  WebinarAvailability,
  WebinarRegistration,
  WebinarsItemUserResponse,
  WebinarsUserClient,
} from "@/clients/clients";
import { mapActions, mapGetters } from "vuex";
import { format } from "date-fns";
import { loginRequest } from "@/configs/authConfig";
import AppPage from "@/components/layout/page/AppPage.vue";
import WebinarSummary from "@/components/webinars/WebinarSummary.vue";
import WebinarDetailsTabs from "@/components/webinars/WebinarDetailsTabs.vue";
import WebinarOnDemandVideo from "@/components/webinars/WebinarOnDemandVideo.vue";
import WebinarPromotionalImage from "@/components/webinars/WebinarPromtionalImage.vue";

export default Vue.extend({
  name: "WebinarEnrolledView",
  metaInfo() {
    const title = this.webinar?.title ?? "Thornton & Ross";
    return { title };
  },
  components: {
    WebinarPromotionalImage,
    WebinarOnDemandVideo,
    WebinarDetailsTabs,
    WebinarSummary,
    AppPage,
  },
  props: {
    id: String,
  },
  data: () => ({
    errorMessage: "",
    webinar: null as WebinarsItemUserResponse | null,
    tab: null,
    loading: false,
    showPassword: false,
  }),
  computed: {
    ...mapGetters(["assetBaseUrl"]),
    ...mapGetters("UserStore", ["user"]),
    webinarAvailability(): typeof WebinarAvailability {
      return WebinarAvailability;
    },
    availability(): string {
      return this.webinar?.availability ?? "";
    },
    minContentHeight(): string {
      if (this.$refs["descriptionPanel"]) {
        const height = (this.$refs["descriptionPanel"] as HTMLElement)
          ?.offsetHeight;
        return `${height}px`;
      }
      return "300px";
    },
    joinUrl(): string {
      const registration: WebinarRegistration | undefined =
        this.user.webinars?.find(
          (p: WebinarRegistration) => p.id === this.webinar?.id
        );
      return registration?.joinUrl ?? "";
    },
    title(): string {
      return this.webinar?.title ?? "";
    },
    certificate(): UserCertificateResponse | null {
      return (
        this.user.certificates?.find(
          (p: UserCertificateResponse) => p.module?.id === this.webinar?.id
        ) || null
      );
    },
  },
  async created(): Promise<void> {
    await this.getWebinar();
  },
  methods: {
    ...mapActions("UserStore", ["getUser"]),
    async getWebinar(): Promise<void> {
      this.loading = true;
      this.errorMessage = "";
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const client = this.$httpClientFactory(
        WebinarsUserClient,
        token.accessToken
      );
      try {
        this.webinar = await client.webinarDetails(this.id);
      } catch (e) {
        this.errorMessage =
          (e as ApiException).message ?? "Unknown error occurred.";
      } finally {
        this.loading = false;
      }
    },
    formattedDate(): string {
      if (this.webinar?.date) {
        return format(this.webinar.date, "eeee, do MMMM yyyy");
      }
      return "";
    },
    formattedSpeakerShortName(speaker: SpeakerPublicResponse): string {
      return [speaker.title, speaker.fullName]
        .filter((p) => p?.length)
        .join(" ");
    },
    formattedSpeakerFullName(speaker: SpeakerPublicResponse): string {
      return [speaker.title, speaker.fullName, speaker.postNominalLetters]
        .filter((p) => p?.length)
        .join(" ");
    },
    async certificateEarned() {
      this.errorMessage = "";
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const client = this.$httpClientFactory(UserClient, token.accessToken);
      try {
        await client.generateCertificate(
          new GenerateCertificateRequest({ webinarId: this.id })
        );
        await this.getUser();
      } catch (e) {
        this.errorMessage =
          (e as ApiException).message ?? "Unable to generate certificate.";
      }
    },
  },
});
