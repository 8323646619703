









import Vue, { PropType } from "vue";
import { atcb_action } from "add-to-calendar-button";
import { WebinarsItemUserResponse } from "@/clients/clients";
import { format } from "date-fns";

export default Vue.extend({
  name: "WebinarAddToCalendar",
  props: {
    webinar: {
      type: Object as PropType<WebinarsItemUserResponse>,
    },
    joinUrl: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  methods: {
    addToCalendar(): void {
      const date = format(this.webinar?.date ?? Date.now(), "yyyy-MM-dd");
      const description = `Thornton & Ross Webinar series\n\n url: ${
        this.joinUrl
      } \n\n ${this.webinar?.providerConfig?.agenda ?? ""}`;
      atcb_action({
        name: this.webinar?.title ?? "",
        description: description,
        startDate: date,
        endDate: date,
        startTime: this.webinar?.startTime ?? "",
        endTime: this.webinar?.endTime ?? "",
        options: [
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "MicrosoftTeams",
          "Outlook.com",
        ],
        timeZone: "Europe/London",
        iCalFileName: `thornton_and_ross_webinar_${this.webinar?.date?.getFullYear()}-${this.webinar?.date?.getMonth()}-${this.webinar?.date?.getDay()}`,
        trigger: "click",
      });
    },
  },
});
