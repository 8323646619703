















import Vue from "vue";
import WebinarPromotionalImage from "@/components/webinars/WebinarPromtionalImage.vue";
import { WebinarAvailability } from "@/clients/clients";
import WebinarVideoPlayer from "@/components/webinars/WebinarVideoPlayer.vue";

export default Vue.extend({
  name: "WebinarOnDemandVideo",
  components: { WebinarVideoPlayer, WebinarPromotionalImage },
  props: {
    url: {
      type: String,
    },
    promotionalImageUri: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    onDemand(): WebinarAvailability {
      return WebinarAvailability.OnDemand;
    },
  },
});
