








import Vue from "vue";
import Player, { Options } from "@vimeo/player";
import AppLoading from "@/components/layout/AppLoading.vue";

export default Vue.extend({
  name: "WebinarVideoPlayer",
  components: { AppLoading },
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    player: null as Player | null,
  }),
  async mounted() {
    const options: Options = {
      url: this.url,
      responsive: true,
    };
    const element = this.$refs["video-player"];
    this.player = new Player(element as HTMLElement, options);
    this.player.on("loaded", () => (this.loading = false));
    const duration = (await this.player?.getDuration()) ?? 0;
    const certificatePoint = Math.floor(duration * 0.75);
    this.player.addCuePoint(certificatePoint, { certificateEarned: true });
    this.player.on(
      "cuepoint",
      ({ data }: { data: { [key: string]: boolean } }) => {
        if (data["certificateEarned"]) this.$emit("update:certificate");
      }
    );
  },
});
