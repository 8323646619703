














import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  ApiException,
  SharedReferenceResponse,
  WebinarsUserClient,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";
import AppPage from "@/components/layout/page/AppPage.vue";
import AppGdprDialog from "@/components/dialogs/AppGdprDialog.vue";

export default Vue.extend({
  name: "WebinarRegistrationView",
  metaInfo: {
    title: "Thornton & Ross",
  },
  components: { AppGdprDialog, AppPage },
  props: {
    id: String,
  },
  data: () => ({
    loading: true,
    errorMessage: "",
    showGdprDialog: false,
  }),
  computed: {
    ...mapGetters("UserStore", ["user", "userGdprConsent"]),
  },
  async created() {
    this.errorMessage = "";
    const enrolled = this.user.webinars?.some(
      (p: SharedReferenceResponse) => p.id === this.id
    );
    if (enrolled) {
      await this.$router.push({ name: "Webinar", params: { id: this.id } });
      return;
    }
    if (this.userGdprConsent) {
      await this.enroll();
      return;
    }

    this.showGdprDialog = true;
  },
  methods: {
    ...mapActions("UserStore", ["getUser"]),
    async enroll() {
      try {
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          WebinarsUserClient,
          token.accessToken
        );
        await client.enrollParticipant(this.id);
        await this.getUser();
        await this.$router.push({ name: "Webinar", params: { id: this.id } });
        return;
      } catch (e) {
        this.errorMessage =
          (e as ApiException).message ?? "Unknown error occurred";
        this.loading = false;
      }
    },
    async redirectToWebinar() {
      this.showGdprDialog = false;
      await this.$router.push({ name: "Webinar", params: { id: this.id } });
    },
  },
});
